import { TimestampInterval } from './TimeInterval';

export const DateUtils = {

  months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Maй', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
  months2: ['Января', 'Февраля', 'Марта', 'Апреля', 'Maя', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'],
  days: ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'],
  daysShort: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],

  isValid: (date?: string | Date | number | null) => !!date && !isNaN(new Date(date).getTime()),

  toDateArray: (d: Date): [number, number, number, number, number] => {
    if (typeof d === 'string') {
      d = new Date(d);
    }
    return [d.getUTCFullYear(), d.getUTCMonth() + 1, d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes()];
  },

  hoursToMs: (h: number): number => {
    return h * 60 * 60 * 1000;
  },

  /**
   * 2023-06-03 --> Date
   */
  toDateTimeFromSql: (date: Date | string, time: Date): Date => {
    if (typeof date !== 'string') {
      return DateUtils.toDateTime(date, time);
    }
    const [year, month, day] = date.split('-').map(v => parseInt(v));
    return DateUtils.getDate({
      year,
      month: month - 1,
      date: day,
      hours: time.getHours(),
      minute: time.getMinutes()
    });
  },

  toDateTime: (date: Date, time: Date): Date => {
    return DateUtils.getDate({
      year: date.getFullYear(),
      month: date.getMonth(),
      date: date.getDate(),
      hours: time.getHours(),
      minute: time.getMinutes()
    });
  },

  toMonthYear: (d: Date): string => {
    return DateUtils.toString(d, { hideYear: true, hideDay: true, monthString: true }) + ' ' + d.getFullYear();
  },

  toString: (d: Date, options?: {
    hideYear?: boolean,
    hideMonth?: boolean,
    hideDay?: boolean,
    monthString?: boolean
  }): string => {
    if (!d) {
      return '';
    }
    if (['string', 'number'].includes(typeof d)) {
      d = new Date(d);
    }
    const data = [];
    if (!options?.hideDay) {
      const day = d.getDate();
      data.push(day > 9 ? day : '0' + day);
    }
    if (!options?.hideMonth) {
      const month = d.getMonth() + 1;
      data.push(
        options?.monthString
          ? DateUtils[options?.hideDay ? 'months' : 'months2'][month - 1]
          : month > 9 ? month : '0' + month
      );
    }
    if (!options?.hideYear) {
      data.push(d.getFullYear());
    }
    return data.join(options?.monthString && !options?.hideMonth ? ' ' : '.');
  },

  dateDiffInDays(d1: Date, d2: Date): number {
    const MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate());
    const utc2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate());
    return Math.floor((utc1 - utc2) / MS_PER_DAY);
  },

  getDayLabel(d?: Date, showDate?: boolean): string {
    if (!d) {
      return '';
    }
    d = new Date(d);
    const difference = DateUtils.dateDiffInDays(d, new Date());
    switch (difference) {
      case 0 :
        return 'Сегодня';
      case 1 :
        return 'Завтра';
      default :
        return showDate || difference > 4
          ? DateUtils.toString(d, { hideYear: true })
          : DateUtils.getWeekDay(d);
    }
  },

  toStringTime(d?: Date, GMT?: number): string {
    if (!d) {
      return '';
    }
    d = new Date(d);
    const h = typeof GMT === 'number' ? d.getUTCHours() + GMT : d.getHours();
    const m = typeof GMT === 'number' ? d.getUTCMinutes() : d.getMinutes();
    return `${ h > 9 ? h : '0' + h }:${ m > 9 ? m : '0' + m }`;
  },

  getWeekDayNumber: (d: Date): number => {
    return (d.getDay() || 7) - 1;
  },

  getWeekDay: (d: Date, full = false): string => {
    return DateUtils[full ? 'days' : 'daysShort'][(d.getDay() || 7) - 1];
  },

  getIntervalForMonth: (monthOffset: number, onlyFuture = true): { start: Date, end: Date } => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    return {
      start: DateUtils.getDate({
        month: currentMonth + monthOffset,
        date: onlyFuture && monthOffset === 0 ? 0 : 1,
        hours: 0, minute: 0
      }),
      end: DateUtils.getDate({
        month: currentMonth + monthOffset + 1,
        date: 1, hours: 0, minute: 0
      })
    };
  },

  getDateWithOffset: (source: Date, offset: {
    months?: number,
    days?: number,
    hours?: number,
    minutes?: number,
  }): Date => {
    return new Date(
      source.getFullYear(),
      source.getMonth() + (offset.months ?? 0),
      source.getDate() + (offset.days ?? 0),
      source.getHours() + (offset.hours ?? 0),
      source.getMinutes() + (offset.minutes ?? 0)
    );
  },

  getDayMid: (d: Date): Date => {
    return DateUtils.getDateWithOffset(DateUtils.getDayStartFromSource(d), { hours: 12 });
  },

  getDayStartFromSource: (source: Date, dayOffset = 0, zone?: number): Date => {
    if (!zone && zone !== 0) {
      zone = -new Date().getTimezoneOffset() / 60;
    }
    source = new Date(source);
    const timestamp = new Date(
      source.getUTCFullYear(),
      source.getUTCMonth(),
      source.getUTCDate() + dayOffset,
      source.getUTCHours() - source.getHours(),
      source.getUTCMinutes() - source.getMinutes(),
      0,
      0
    ).getTime();
    return new Date(timestamp + zone * 60 * 60 * 1000);
  },

  getDayStart: (dayOffset = 0, zone?: number): Date => {
    return DateUtils.getDayStartFromSource(new Date(), dayOffset, zone);
  },

  getDayEnd: (dayOffset = 0, zone?: number): Date => {
    return DateUtils.getDayStartFromSource(new Date(), dayOffset + 1, zone);
  },

  getDate: (d: {
    year?: number,
    month?: number,
    date?: number,
    hours?: number,
    minute?: number
  }, source?: Date): Date => {
    const date = source || new Date();
    return new Date(
      d.year ?? date.getFullYear(),
      d.month ?? date.getMonth(),
      d.date ?? date.getDate(),
      d.hours ?? date.getHours(),
      d.minute ?? date.getMinutes()
    );
  },

  getTimeFromString: (t: string): Date => {
    const hours = parseInt(t.split(':')[0]);
    const minute = parseInt(t.split(':')[1]);
    return DateUtils.getDate({ hours, minute });
  },

  daysInMonth: (y: number, m: number): number => {
    return 32 - new Date(y, m, 32).getDate();
  },

  getMothOffset(d: Date) {
    const current = new Date();
    return (d.getFullYear() * 12 + d.getMonth()) - (current.getFullYear() * 12 + current.getMonth());
  },

  getISODate(d: Date): Date | string {
    return d.toISOString().split('T')[0];
  },

  /**
   * parse date like 14.04.2023 19:30
   * @param t -- string of format dd.MM.yyyy hh:mm
   */
  fromAmeliaString(t?: string): Date | undefined {
    if (!t) {
      return;
    }
    const [date, time] = t?.split(' ');
    if (!date || !time) {
      return;
    }
    const [day, month, year] = date.split('.');
    const [hour, minute] = time.split(':');
    if (!day || !month || !year || !hour || !minute) {
      return;
    }
    return new Date(+year, +month - 1, +day, +hour, +minute);
  },

  equalTime(t1: Date, t2: Date): boolean {
    t1 = new Date(t1);
    t2 = new Date(t2);
    return t1.getHours() === t2.getHours() &&
      t2.getMinutes() === t2.getMinutes();
  },

  max(d1: Date, d2: Date): Date {
    return new Date(Math.max(new Date(d1).getTime(), new Date(d2).getTime()));
  },

  roundFrom(date: Date, start: Date, minutes: number, type: 'round' | 'ceil' = 'round'): Date {
    const startMinutes = start.getHours() * 60 + start.getMinutes();
    const minutesFromStart = (date.getHours() * 60 + date.getMinutes()) - startMinutes;

    const targetTime = startMinutes + Math[type](minutesFromStart / minutes) * minutes;
    const newHours = Math.round(targetTime / 60);
    const newMinutes = targetTime - newHours * 60;

    date.setHours(newHours);
    date.setMinutes(newMinutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  },

  round(date: Date, minutes: number, type: 'round' | 'ceil' = 'round'): Date {
    let time = date.getHours() * 60 + date.getMinutes();
    time = Math[type](time / minutes) * minutes;
    const newHours = Math.round(time / 60);
    const newMinutes = time - newHours * 60;

    date.setHours(newHours);
    date.setMinutes(newMinutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  },

  getIntervalDurationMinutes(e: TimestampInterval): number {
    return Math.round((new Date(e.end).getTime() - new Date(e.start).getTime()) / 60000);
  },

  setTimeZone(date: Date, zone: number): Date {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    const offset = zone + date.getTimezoneOffset() / 60;
    return new Date(date.getTime() + DateUtils.hoursToMs(offset));
  },

  /**
   * @returns number of milliseconds between t1 and t2; (t1 - t2)
   */
  diff(t1: Date | string, t2: Date | string): number {
    if (typeof t1 === 'string') {
      t1 = new Date(t1);
    }
    if (typeof t2 === 'string') {
      t2 = new Date(t2);
    }
    return t1.getTime() - t2.getTime();
  },

  extractTime(d: Date): Date {
    const zero = new Date(0, 0, 0);
    zero.setUTCHours(d.getUTCHours());
    zero.setUTCMinutes(d.getUTCMinutes());
    return zero;
  },

  getDurationString(ms: number, separator = ''): string {
    const dimensions = [1000, 60, 60, 24, 30];
    const labels = ['мс', 'сек', 'мин', 'час', 'дн', 'мес'];
    const arr = dimensions.map(() => 0);
    arr[0] = ms;
    for (let i = 1; i < arr.length; i++) {
      const d = dimensions[i - 1];
      if (arr[i - 1] >= d) {
        arr[i] = Math.floor(arr[i - 1] / d);
        arr[i - 1] -= arr[i] * d;
      }
    }
    const result = [];
    for (let i = arr.length - 1; i >= 0; i--) {
      if (arr[i]) {
        result.push(arr[i] + separator + labels[i])
      }
    }
    return result.join(' ');
  },

  isDateEqual(d1: Date, d2: Date) {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
  }
};

export class ZoneUtils {
  private constructor(private readonly zone: number) {
  }

  static withZone(zone: number): ZoneUtils {
    return new ZoneUtils(zone);
  }

  get(t: Date): Date {
    const offset = this.zone + t.getTimezoneOffset() / 60;
    return new Date(t.getTime() + DateUtils.hoursToMs(offset));
  }

  getDate(date?: Date): number {
    const t = date ? new Date(date) : new Date();
    const offset = this.zone + t.getTimezoneOffset() / 60;
    return new Date(t.getTime() + DateUtils.hoursToMs(offset)).getDate();
  }

  getHours(date?: Date): number {
    const t = date ? new Date(date) : new Date();
    const utc = t.getUTCHours();
    let result = utc + this.zone;
    while (result > 24) {
      result -= 24;
    }
    while (result < 0) {
      result += 24;
    }
    return result;
  }

  getTime(t: Date): Date {
    const offset = this.zone + t.getTimezoneOffset() / 60;
    const zoned = new Date(t.getTime() + DateUtils.hoursToMs(offset));

    return new Date((zoned.getHours() * 60 + zoned.getMinutes()) * 60 * 1000);
  }
}

// if (window) { // @ts-ignore
//   window.dateUtils = DateUtils; // @ts-ignore
//   window.ZoneUtils = ZoneUtils;
// }
