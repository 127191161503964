import { Address } from '../dto/Organization';

export interface EqualityOptions {
  separator?: string;
  equalityFunction?: (w1: string, w2: string) => boolean
}

export const StringUtils = {
  /**
   * method test equality of two word sets
   * @param s1 -- first words set
   * @param s2 -- second words set
   * @param options -- ignore case default true; separator default " ";
   * @return number of matched words
   * @example
   * equalWords('aaa bbb ccc', 'BBB ccc eeee') // returns 2
   */
  equalWordsCount: (s1: string, s2: string, options?: EqualityOptions): number => {
    if (!s1?.length || !s2?.length) return 0;
    const parts1 = s1.trim().split(options?.separator || ' ').map(s => s.trim()).filter(s => s.length);
    const parts2 = s2.trim().split(options?.separator || ' ').map(s => s.trim()).filter(s => s.length);
    const equalityFunction = options?.equalityFunction ||
      ((w1, w2) => w1.toLowerCase() === w2.toLowerCase())
    let matches = 0;
    for (const w1 of parts1) {
      for (const w2 of parts2) {
        if (equalityFunction(w1, w2)) {
          matches++;
          break;
        }
      }
    }
    return matches;
  },

  isEmailValid(m?: string): boolean {
    return !!m && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(m);
  },

  getWordsEquality: (w1: string, w2: string): number => {
    if (w1.toLowerCase() === w2.toLowerCase()) {
      return 1;
    }
    const [wMin, wMax] = w1.length < w2.length
      ? [w1.toLowerCase(), w2.toLowerCase()]
      : [w2.toLowerCase(), w1.toLowerCase()]

    const bagMin = wMin.split("");
    const bagMax = wMax.split("");
    let bagScore = 0;
    for (const char of bagMin) {
      const findIndex = bagMax.findIndex(c => c === char);
      if (findIndex >= 0) {
        bagScore++;
        bagMax.splice(findIndex, 1);
      }
    }
    let finalScore = bagScore / (wMax.length + 1);
    if (wMax.startsWith(wMin) || wMax.endsWith(wMin)) {
      finalScore = (finalScore + 1.5) / 2.5;
    } else if (wMax.includes(wMin)) {
      finalScore = (finalScore * 2 + 1) / 3;
    }
    return finalScore;
  },

  capitalizeFirst: (s: string, lowerRest?: boolean): string => {
    if (!s?.length || s.trim().length < 1) {
      return "";
    }
    let end = s.substring(1);
    lowerRest && (end = end.toLowerCase());
    return s.trim().charAt(0).toUpperCase() + end;
  },

  capitalizeAll: (s: string, split?: string, lowerRest?: boolean): string =>
    s.split(split || " ").map(w => StringUtils.capitalizeFirst(w, lowerRest)).filter(Boolean).join(split || " "),

  ilike(source: string, search: string, options?: EqualityOptions): boolean {
    if (!source?.length) {
      return false;
    }
    if (!search?.length) {
      return true;
    }
    const sourceParts = source.toLowerCase().trim().split(options?.separator || ' ').map(s => s.trim()).filter(s => s.length);
    const searchParts = search.toLowerCase().trim().split(options?.separator || ' ').map(s => s.trim()).filter(s => s.length);
    for (const searchWord of searchParts) {
      const found = sourceParts.some(s => s.includes(searchWord));
      if (!found) {
        return false;
      }
    }
    return true;
  },

  buildShortName: (s: string | Record<string, any>): string => {
    if (!s) {
      return "";
    }
    if (typeof s !== 'string') {
      const { lastName, firstName, fatherName } = s;
      if (!lastName || !firstName || !fatherName) {
        s = s['name'];
      }
      else {
        return StringUtils.capitalizeFirst(lastName) + ' ' + firstName.charAt(0).toUpperCase() + '.' + fatherName.charAt(0).toUpperCase() + '.';
      }
    }
    if (typeof s === 'string') {
      const parts = s.trim().split(' ');
      if (parts.length !== 3) {
        return s;
      }
      const [lastName, firstName, fatherName] = parts;
      return StringUtils.capitalizeFirst(lastName) + ' ' + firstName.charAt(0).toUpperCase() + '.' + fatherName.charAt(0).toUpperCase() + '.';
    }
    return "";
  },

  buildName(...words: string[]): string {
    return words.map(w => w?.trim()).filter(w => w?.length).join(" ");
  },

  getNameToken(name: string): string {
    if (!name.length) {
      return '';
    }

    const nameParts = name.split(" ")
      .map(n => n.toLowerCase().trim())
      .filter(p => p.length)
      .sort((p1, p2) => p1.localeCompare(p2));

    return nameParts.join("_");
  },

  getLegalAddress(a: Address): string {
    return `${a.cityType || 'г'}. ${a.city}, ул. ${a.street}`;
  },

  getFullAddress(a: Address): string {
    let res = StringUtils.getLegalAddress(a);
    if (a.entrance) {
      res += ` (${a.entrance})`;
    }
    return res;
  },

  trimSlash(str?: string | number): string {
    if (!str) {
      return '';
    }
    if (typeof str !== 'string') {
      str = String(str);
    }
    if (str.charAt(0) === '/') {
      str = str.substring(1);
    }
    if (str.charAt(str.length - 1) === '/') {
      str = str.substring(str.length - 1);
    }
    return str;
  }
};